body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto !important;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.campaign-item > .MuiCardContent-root {
  padding: 8px;
  padding-bottom: 8px !important;
}

.campaign-type-title {
  color:#000;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  background-color: #b3f5ec;
}
.wrapper.production .campaign-type-title {
  background-color: #ff6060;
  color:#fff
}
.campaign-type-title__amount {
  float: right;
  color:#000;
  background-color: #fff;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 5px;
}
.campaign-type-title:hover {
  color:#f00
}
.campaign-group {
  margin-bottom:16px;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5)
}
.campaigns-list {background-color: #efefef}
.campaigns-list.variants .campaign-item__body>div {display: flex}
.campaigns-list.variants .campaign-item__body>div:last-child {margin-left: auto}
.campaigns-list.variants .campaign-item__body {display: flex; alignitems: center; justify-content: space-between}

.campaign-item.MuiCard-root {
  margin-bottom:10px;
  box-shadow: none;
  border:0;
  border-radius: 0;
  border-top:1px #777 solid;
  border-bottom:1px #777 solid
}
.campaign-item:hover {
  background-color: #efefef;
  transition: all ease 0.2s;
}
.campaign-item__header {
  display: flex;
}
.campaign-item__header:after,.campaign-audience:after {
  content: '';
  clear: both;
  display: block;
}
.campaign-item__name {
  flex:1;
  padding-right: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  width: 30%;
  word-wrap: break-word;
  align-items: center;
  display: flex;
  max-height: 35px;
}
.campaign-item__name span {
  }
.campaign-item__name>div {display: inline;max-width: 100%}
.campaign-item__tags {
  float: left;
}
.campaign-item__tags>span {
  padding: 0px 5px;
  background-color: #444;
  color:#fff;
  margin-right: 3px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  display: block;
  margin-bottom: 2px;
}
.MuiRadio-root {
  padding: 0 9px !important;
}
.MuiFormControlLabel-label {
  line-height: 19px !important;
}

.campaign-edit {
  float: right;
  font-size: 12px;
  padding-left: 10px;
}
.campaign-timewrap {
  float: right;
  text-align: right;
  font-size: 10px;
  width: 205px;
}
.campaign-timewrap>div {
  margin-left:5px
}
.campaign-edit__link {

}
.campaign-details {
  padding-top: 8px
}
.campaign-status {
  float: right;
}
.campaign-actions {
  float: right;
  width: 20px;
  margin-top: -2px;
}
.campaign-actions svg {
  font-size: 18px !important;
}
.campaign-actions>div {
  line-height: 12px;
}
.campaign-gens {
  float: right;
  width: 45px;
}
.campaign-status .MuiFormControl-root svg{
  right: -2px !important;
}
.campaign-status .MuiFormControl-root{
  width: 100px;
}
.campaign-status .MuiSelect-select{
  font-size: 11px;
  line-height: 18px;
  padding: 5px 0 5px 5px !important;
}
.campaign-item__starttime {
  font-weight: bold;
}
.campaign-item__endtime {
  font-weight: bold;

}
.campaign-timewrap.aqua .campaign-item__starttime,
.campaign-timewrap.aqua .campaign-item__endtime
{
  color: #99cedf;
}
.campaign-timewrap.lightgreen .campaign-item__starttime ,
.campaign-timewrap.lightgreen .campaign-item__endtime {
  color: #9cce9b;
}
.campaign-timewrap.green .campaign-item__starttime ,
.campaign-timewrap.green .campaign-item__endtime {
  color: #2f8f2c;
}
.campaign-timewrap.yellow .campaign-item__starttime ,
.campaign-timewrap.yellow .campaign-item__endtime {
  color: #d1a610;
}
.campaign-timewrap.red .campaign-item__starttime ,
.campaign-timewrap.red .campaign-item__endtime{
  color: #e65959;
}

.campaign-timeblock {
  float: left;
}
.campaign-item__countdown-timer {
  font-size: 10px;
  color:#666
}
.campaign-timeblock .campaign-item__countdown-timer {
  margin-bottom: 5px;
}
.campaign-audience {

}
.MuiTypography-root.abtest-cohorts{
  color: #D3D3D3;
  font-size: 12px;
}
.cohort-active {
  background-color: #8be8a3;
}
.cohort-inactive {
  background-color: #ff9797
}
.campaign-item__footer {
  padding-top: 5px;
  clear: both;
}
.campaign-details .campaign-audience.left {
  float: left;
  width: calc(100% - 250px);
}
.campaign-details>.campaign-audience.right {
  float: right;
}

.audience-item {margin-bottom:0px; clear: both}
.audience-item__connected {color:#ccc;margin-left: 20px}
.audience-item__inline {
  display: inline;
  padding-right: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  max-width: 400px;
}
.audience-item__inline>div {
  margin-right: 3px;
  font-size: 12px;
  padding: 3px;
  height: auto !important;
  margin-bottom: 2px;
}
.audience-item__inline>div>span {
  padding: 0 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: auto;
}
.audience-item__inline__right {
  display: inline-block;
  padding-right: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  float: right;
}
.audience-item__inline__right>div {
  margin-right: 3px;
  font-size: 12px;
  padding: 3px;
  height: 24px !important;
  margin-bottom: 2px;
}
.audience-item__inline__right>div>span {
  padding: 0 5px;
}

.campaign-item__checkbox {
  display: flex;
  max-height: 35px;
}
.campaign-item__warning {
  align-items: center;
  display: flex;
  max-height: 35px;
}
.campaign-item__checkbox > span{
  padding: 0;
}
.campaign-item__body {

  cursor: pointer;

}
.campaign-item__body:after {
  clear: both;
  content: '';
  display: block;
}

.MuiChip-root.MuiChip-filled.MuiChip-sizeSmall.MuiChip-colorError{
  background-color: #ff9797;
  color: #000;
}
.MuiChip-root.MuiChip-filled.MuiChip-sizeSmall.MuiChip-colorSuccess{
  background-color: #8be8a3;
  color: #000;
}

.MuiMenuItem-root.SUSPENDED {
  background-color: #ffcd5f !important;
}
.MuiMenuItem-root.ACTIVE {
  background-color: #8be8a3 !important;;
}
.MuiMenuItem-root.HIDDEN {
  background-color: #c6e5e5 !important;;
}
.MuiMenuItem-root.DEACTIVATED {
  background-color: #ff9797 !important;;
}
.MuiMenuItem-root.COMPLETED {
  background-color: #8bd7e8 !important;;
}

.MuiFormControl-root>.SUSPENDED {
  background-color: #ffcd5f !important;;
}
.MuiFormControl-root>.ACTIVE {
  background-color: #8be8a3 !important;;
}
.MuiFormControl-root>.HIDDEN {
  background-color: #c6e5e5 !important;;
}
.MuiFormControl-root>.DEACTIVATED {
  background-color: #ff9797 !important;;
}
.MuiFormControl-root>.COMPLETED {
  background-color: #8bd7e8 !important;;
}
.win-cohort {
  background-color: #8bd7e8;
  font-weight: bold;
  text-align: right;

  font-size: 12px;
  margin: 3px -8px -8px -8px;
  padding: 0 8px;
}

.MuiToolbar-root .MuiBox-root {
  color:#000
}
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  font-weight: bold;
}
.MuiButtonBase-root.MuiMenuItem-root:hover {
  opacity: 0.8;
}

div.MuiToolbar-root {
  min-height: 48px;
}
main.main-menu-wrap {
  padding: 6px;
}

.fix-top-transfer button {
  padding: 7px 10px !important;
  min-width: auto !important;
}

.env-btn {
  flex:0 0 55px
}
.env-select {
  flex: 1 1 auto;
  flex-grow: 1;
  min-width:220px;
}
.fix-top-transfer {
  position: fixed;
  top: 3px;
  z-index: 1299;
  left: 0;
  width: 700px !important;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1500px) {

  .env-btn {
    flex:0 0 55px
  }
  .env-select {
    flex: 1 1 auto;
    flex-grow: 1;
    min-width: 200px
  }
  .fix-top-transfer {
    position: fixed;
    top: 3px;
    z-index: 1299;
    left: 0;
    width: 650px !important;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 1300px) {

  .env-select {
    flex: 1 1 auto;
    flex-grow: 1;
    max-width:100%;
    min-width:0;
  }
  .fix-top-transfer {
    position: sticky;
    width: 100% !important;
    left: 0;
    top:123px;
    background-color: #fff;
    transform: translateX(0%);
    margin-bottom: 20px !important;
    z-index: 10;
  }
}

.snackbar-wrapper {
  position: fixed;
  bottom: 24px;
  left: 24px;
}
.snackbar-wrapper > div {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: 4px;
}

.collapse-all-link {
  font-size: 12px;
  line-height: 14px;
  border-bottom: 1px #ed6c02 dashed;
  color:#ed6c02;
  display: inline-block;
  margin-bottom: 7px;
  cursor: pointer;
}
.MuiDivider-root>span {
  color:#777
}
.MuiList-root>.MuiListSubheader-root {
  font-weight: bold;
  text-align: center;
  padding: 0 !important;
}
.MuiList-root>.MuiButtonBase-root+.MuiListSubheader-root {
  border-top:1px #ccc solid;
}

.filters-sticky {
  position: sticky;
  top: 47px;
  background: #fff;
  padding: 10px 0;
  z-index: 50;
}

@font-face {
  font-family: 'Hagrid';
  src: url('./assets/hagrid.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.login-title {
  font-family: "Hagrid";
  font-size: 150px;
  line-height: 150px;

  position: absolute;
  top:50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 700px;

  cursor: pointer;
}
.login-title>div {
  display: inline
}
.animation {
  height: 250px;
  width: 250px;
  display: inline-block;

}
@keyframes rotate {
  100% {
    transform: rotate(360deg) translate3d(0, 0, 0.03px);
  }
}
.animation {
  width: 250px;
}
.animation>img {
  width: 250px;
  height: 250px;
  border-radius: 100%;

  animation: rotate 10s linear infinite;
  position: absolute;
  z-index: 5;
  top: -1px;
  left: -1px;

  margin-top: -38px;
  margin-left: 72px;
}
.animation video {
  min-width: 150px;
  min-height: 150px;
  height: 150px;
  width: 150px;
  text-align: center;
  margin: auto;
  margin-left: 40px;
}
.MuiDialogContent-root {
  padding-top: 5px !important;
}
.MuiDataGrid-columnHeaderTitle {
  white-space: normal !important;
  line-height: 20px !important;
}

.MuiList-root.MuiList-padding {
  padding: 0 !important;
}
.header header {
  background-color: #fff !important;
  padding-right: 0px !important;
}
#root[aria-hidden="true"] .fix-top-transfer {
}

.word-wrap {
  word-wrap: break-word;
  display: block;
}
.MuiPopper-root .MuiPaper-root { padding: 0 !important; }
.MuiPopper-root .MuiPaper-root .MuiAutocomplete-listbox { padding: 0 !important; }
.MuiPopper-root {
  padding: 0 !important;
}
.disabledMenu {
  opacity: 0.5 !important;
  cursor: default !important;
}
.campaign-item__name {
  position: relative;
}
.campaign-item__name-tagsWrapper {
  position: absolute;
  bottom: -20px;
  left: 0;
}
.campaign-item__name-tag {
  background-color: #888;
  display: inline-block;
  width: auto;
  border-radius: 5px;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #fff;
  margin-right: 3px;

}

.history-item {
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  border:1px #ddd solid;
  font-size: 14px;
  line-height: 24px;
}

.history-item-group.expanded .history-item:first-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

/* Для последнего элемента */
.history-item-group.expanded .history-item:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Для всех промежуточных элементов */
.history-item-group.expanded .history-item:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  margin-bottom: 0;
}

.history-item-group.expanded>.history-item.expanded {

}
.history-item-group.expanded>.history-item:last-child {

}
.history-item button {
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 2px 10px !important;
  text-transform: none !important;
}
.history-item:after {
  clear: both;
  content: '';
  display: block;
}
.history-item>div {
  float: left;
  margin-right: 5px;
}
.history-item__date {
  color:#aaa
}
.history-item>div.history-details {
  float: right;
  margin-right: 0;
}
.history-owner {
  font-weight: bold;
}
.history-owner.mephisto {
  color: #750fa2
}
.history-owner.teamcity,.history-owner.teamcity a  {
  text-decoration: none !important;
  color: #3879c7
}
.status {text-transform: uppercase; font-weight: bold;}
.status-hidden { color: #abd6d6 }
.status-suspended { color: #ffcd5f }
.status-deactivated { color: #ff8787 }
.status-completed { color: #8bd7e8 }
.status-active { color: #74df90 }
.history-item .tag {background-color: #444; color:#fff; padding: 2px 4px;font-size: 12px; line-height: 14px; maring:0 4px; display: inline-block; border-radius: 3px}

.history-details {
  margin-left: 5px;
}
.history-item table td {padding: 0 !important;border: 0!important;vertical-align: top !important;}
.history-item table tr {padding: 0;}
.history-item a {text-display: none;line-height: inherit !important; font-size: inherit !important; color: inherit !important;padding: 0 !important;}
a.button-outlined {
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 2px 10px !important;
  text-transform: none !important;
  border:1px rgba(3, 144, 127) solid !important;
  color: rgba(3, 144, 127) !important;
  text-decoration: none !important;
  border-radius: 5px;
}
.bundle_rollback {
  color: #ef6401
}
.history-item__related {
  padding-left: 30px;
}
.history-item__related .history-item__date {
  position: relative;
}
.history-item__related .history-item-group  {
  position: relative;
}
.history-item__related .history-item-group::before {
  position: absolute;
  content: '';
  display: block;
  left: -15px;
  top: 3px;
  border-top: 0;
  border-bottom: 2px #aaa solid;
  border-left: 2px #aaa solid;
  width: 15px;
  height: 18px;
}
.history-details__platforms {
  padding-left: 50px;
  color:#00f
}

b.production, b.prod {
  color: #d34800
}
.grey {
  color:#ccc
}